import React, { Suspense, useRef, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Typography,
  Grid,
  Divider,
} from "@mui/material";

import CaseDocuments from "./CaseDocuments";
import pdf1 from "../../../assets/Images/pdf1.jpg";
import { useDownloadFile } from "../../../components/atoms/Hooks/useDownloadFile";

const AllDocuments = ({ casedocList, caseloadId }: any) => {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [type, setType] = useState("");
  const activateTab = useRef(1);
  const { handleDownload } = useDownloadFile({ activateTab, type: type });

  const groupedDocs: any = {
    "referral-report": casedocList?.length
      ? casedocList.filter((doc: any) => doc.uploadType === "referral-report")
      : [{ uploadType: "referral-report", message: "No documents available" }],
    "education-report": casedocList?.filter(
      (doc: any) => doc.uploadType === "education-report"
    ),
    "parent-report": casedocList?.filter(
      (doc: any) => doc.uploadType === "parent-report"
    ),
    "mdt-review": casedocList
      ?.filter((doc: any) => doc.uploadType === "mdt-review")
      .sort(
        (a: any, b: any) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      )
      .slice(0, 1),
    caseload: casedocList?.filter((doc: any) => doc.uploadType === "caseload"),
  };

  const renderDocuments = (docs: any[]) =>
    docs.map((doc) => (
      <Grid item  key={doc.id}>
        <Card
          sx={{
            border: "1px solid #ccc",
            boxShadow: "none",
            textAlign: "center",
            minWidth:'200px'
          }}
        >
          <CardContent sx={{ p: 1, pb: "5px!important" }}>
            <img src={pdf1} style={{ maxWidth: "100px" }} />
            <Typography
              color="textSecondary"
              sx={{ textTransform: "capitalize",whiteSpace:'nowrap', my: 1 }}
            >
              {doc.uploadType}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleDownload(null, caseloadId);
                setType(doc.uploadType);
              }}
              sx={{ my: 1 }}
            >
              Download
            </Button>
          </CardContent>
        </Card>
      </Grid>
    ));

  const handlePageCountChange = (newPageCount: number) => {
    setPage(newPageCount);
  };

  const handleLimitCountChange = (newLimitCount: number) => {
    setLimit(newLimitCount);
  };

  const handleSearchCountChange = (newSearchCount: string) => {
    setSearch(newSearchCount);
  };

  return (
    <div>
      <Grid container spacing={3} sx={{ justifyContent: "space-evenly" }}>
        {[
          "referral-report",
          "education-report",
          "parent-report",
          "mdt-review",
        ].map(
          (type) =>
            groupedDocs[type]?.length > 0 && renderDocuments(groupedDocs[type])
        )}
      </Grid>

      {groupedDocs.caseload?.length > 0 && <Divider sx={{ marginY: 3 }} />}
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <Suspense fallback={<div>Loading...</div>}>
            <CaseDocuments
              data={groupedDocs.caseload}
              addResourceBtn={true}
              hideUploadOption={true}
              onPageCountChange={handlePageCountChange}
              onLimitCountChange={handleLimitCountChange}
              onLimitSearchChange={handleSearchCountChange}
            ></CaseDocuments>
          </Suspense>
        </Grid>
      </Grid>
    </div>
  );
};

export default AllDocuments;
