import React, {
  memo,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import {
  Grid,
  Box,
  Card,
  Typography,
  CardContent,
  Divider,
  TextField,
  InputLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import ModeDeleteIcon from "@mui/icons-material/Delete";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import NoCellIcon from "@mui/icons-material/NoCell";
import classes from "./index.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import {
  getUserList,
  addCaseload,
  updateDetailsByCaseload,
  addOtherParentById,
  updateOtherParentById,
  getCaseloadById,
  deleteAdditonalParent,
  updateOverViewParent,
} from "../../../redux/action";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import atoms from "../../atoms";
import { useFormik } from "formik";
import * as Yup from "yup";
import { style } from "./style";
import constant from "../../../constants/constant";
import { postcodeRegex } from "../../../constants/defaultValues";
import { CurrentUserData, ReferralCaseLoad } from "./interface";
import ConfirmationModal from "../../atoms/ConfirmationModal";
import { useSearch } from "../../../pages/App/Referral/Hooks/Usesearch";

const { Button, Modal, Select, Table, LightBox, Slider } = atoms;

const OverviewCaseload = ({ referralCaseload }: any) => {
  const referralReducers = (state: any) => state;

  const statusReducer = createSelector(referralReducers, (state) => ({
    refClosedStatus: state.refClosedReducer,
  }));
  const referralReducer = (state: any) => state?.referralReducer;
  const { refClosedStatus } = useSelector(statusReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location: any = useLocation();
  const { pathname } = location;
  const parts = pathname?.split("/");
  const firstSegment = parts[1];
  const params: any = useParams();
  const [IsSameEmailCheck, setIsSameEmailCheck] = useState(false);
  const [isDeleteTask, setisDeleteTask] = React.useState(false);
  const [isDeleteId, setisDeleteId] = React.useState();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [IsAddParentFlag, setIsAddParentFlag] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const caseloadId: any = queryParams.get("id");
  const [isMode, setIsMode] = useState<any>()
  const [hideWarning, setHideWarning] = useState(false);
  const [key, setKey] = useState(0);
  const [isAddClinicianModel, setIsAddClinicianModel] = useState(false);
  const [isNewSchool, setIsNewSchool] = useState(false);
  const [parentType,setParentType] = useState('')
  const [isButtonEnabled, setButtonEnabled] = useState(false);
  const [isSchoolDetailFilled, setIsSchoolDetailFilled] = useState(false);
  const [clinicianIds, setClinicianIds] = useState([]);
  const [isSeletedClinician, setIsSeletedClinician] = useState([]);
  const [selectClinicianList, setSelectClinicianList] = useState([]);
  const [isCreateCaseload, setIsCreateCaseload] = useState(false);
  const [isEditDetail, setIsEditDetail] = useState(false);
  const [isEditParentDetail, setIsEditParentDetail] = useState(false);
  const [digital_literacy, setdigital_literacy] = useState();
  const [isHomeSchooling, setHomeSchooling] = useState();
  const [gp_details, setGp_details] = useState<any>();
  const [isNewParentDetail, setIsNewParentDetail] = useState<any>();
  const [isNewSchoolDetail, setIsNewSchoolDetail] = useState<any>({});
  const [additional_parent_carers_details, setadditionalParentDetail] =
    useState<any>({});
  const [isSelectedSchoolDetail, setIsSelectedSchoolDetail] = useState<any>("");
  const [isBackupSchoolDetail, setBackupSchoolDetail] = useState<any>({});
  const [isBackupNewSchool, setBackupNewSchool] = useState<any>(false);
  const [currentTab, setCurrentTab] = useState<string>("one");
  const [scope, setScope] = useState<any>("");
  const { query, setQuery, setUserAccess } = useSearch();
  const [placeholderObject, setPlaceholderObject] = useState({
    label: "Please Search by name atleast 3 characters",
    code_number: "placeholder",
    isDisabled: true,
  });
  const [columns, setColumns] = useState([
    {
      name: "#",
      cell: (item: any, ind: number) => <span>{ind + 1}</span>,
      width: "50px",
    },
    {
      name: "Clinicians Name",
      cell: (item: any, ind: number) => <span>{item.label}</span>,
      sortable: false,
      minWidth: "32%",
    },
    {
      name: "Email Address",
      cell: (item: any, ind: number) => <span>{item.email}</span>,
      sortable: false,
      minWidth: "32%",
    },
    {
      name: "Job Role",
      cell: (item: any, ind: number) => <span>{item.jobRole}</span>,
      sortable: false,
      minWidth: "32%",
    },
  ]);
  let schoolInitialValues = {
    first_name: "",
    last_name: "",
    email: "",
    postCode: "",
    phone_no: "",
    address: "",
    school_name: "",
    value: "",
    label: "",
    role: constant.ROLE.ALL_ACCESS,
    scope: constant.SCOPE.CLINICIAN,
  };

  const userLoggedData: CurrentUserData | null = JSON.parse(
    localStorage.getItem("loginResponse") || "null"
  );

  const userReducer = (state: any) => state?.userManagementReducer;

  const getCustomDropdown = (response: any, name: string, last: string) => {
    let dropdown: any = [];
    response &&
      response?.map((value: any) => {
        if (isEditDetail) {
          dropdown.push({
            value: value.id,
            label: last ? value[name] + " " + value[last] : value[name],
            first_name: value[last],
            last_name: value[name],
            email: value.email,
            phone_no: value.phone_no,
            address: value.address,
            jobRole: value.jobRole,
            school_name: value.school_name,
          });
        } else {
          dropdown.push({
            value: value.id,
            label: last ? value[name] + " " + value[last] : value[name],
            email: value.email,
            jobRole: value.jobRole,
            phone_no: value.phone_no,
          });
        }
      });
    return dropdown;
  };

  const userData = createSelector(userReducer, (state) => ({
    userList: getCustomDropdown(
      state?.userData?.user,
      "first_name",
      "last_name"
    ),
  }));

  const allschoolData = createSelector(referralReducer, (state) => ({
    schooList: getCustomDropdown(state?.allschoolData?.user, "school_name", ""),
  }));

  const { userList } = useSelector(userData);
  const { schooList } = useSelector(allschoolData);

  const validation = useFormik<ReferralCaseLoad>({
    enableReinitialize: true,
    initialValues: schoolInitialValues,
    validationSchema: Yup.object({
      first_name: Yup.string().required("Please Enter First Name"),
      last_name: Yup.string().required("Please Enter Last Name"),
      address: Yup.string().required("Please Enter Address"),
      school_name: Yup.string().required("Please Enter School Name"),
      email: Yup.string().required("Please Enter Email"),
      postCode: Yup.string()
        .matches(postcodeRegex, "Invalid postcode format")
        .required("Please Enter Postcode"),
      phone_no: Yup.number().required("Please Enter Mobile Number"),

      // modules: Yup.string().required("Please Select"),
    }),
    onSubmit: (values: ReferralCaseLoad) => {
      setIsNewSchoolDetail("");
      setBackupSchoolDetail(values);
      setBackupNewSchool(isNewSchool);
      setIsSchoolDetailFilled(true);
      setHideWarning(false);
      setIsEditDetail(false);
      let payload = {
        school_detail: values,
        // isNewSchool: isNewSchool,
        isNewSchool: true,
      };
      dispatch(updateDetailsByCaseload(caseloadId, payload));
    },
  });

  useLayoutEffect(() => {
    if (!referralCaseload) return;
    const {
      school_detail,
      parent_detail,
      isNewSchool,
      additional_parent_carers_details,
      digital_literacy,
      gp_details,
      isHomeSchooling,
    } = referralCaseload;
    const values = {
      first_name: school_detail?.first_name,
      last_name: school_detail?.last_name,
      email: school_detail?.contact_email || school_detail?.email,
      postCode: school_detail?.post_name,
      phone_no: school_detail?.contact_phone || school_detail?.phone_no,
      address: school_detail?.address,
      school_name: school_detail?.school_name,
      value: "",
      label: "",
      isNewSchool: isNewSchool,
    };

    const parentValues = {
      first_name: parent_detail?.first_name,
      last_name: parent_detail?.last_name,
      email: parent_detail?.email,
      phone_no: parent_detail?.contact_phone || parent_detail?.phone_no,
      isNewSchool: isNewSchool,
    };
    if (firstSegment === "manage-caseload") {
      setSelectClinicianList(referralCaseload?.clinicialDetails);
      setIsSeletedClinician(referralCaseload?.clinicialDetails);
    }

    setIsNewParentDetail(parent_detail);
    setHomeSchooling(isHomeSchooling);
    setIsNewSchoolDetail(school_detail);
    setBackupSchoolDetail(school_detail);
    setadditionalParentDetail(additional_parent_carers_details);
    setdigital_literacy(digital_literacy);
    setGp_details(gp_details);

    validation.setValues(values);
    validationParent.setValues(parentValues);
    setBackupNewSchool(isNewSchool);

    if (isNewSchool === true) {
      setCurrentTab("one");
    } else {
      setCurrentTab("two");
      setHideWarning(true);
    }
  }, [referralCaseload]);

  const validationParent = useFormik<ReferralCaseLoad>({
    enableReinitialize: true,
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      postCode: "",
      phone_no: "",
      role: constant.ROLE.ALL_ACCESS,
      scope: constant.SCOPE.CLINICIAN,
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("Please Enter First Name"),
      last_name: Yup.string().required("Please Enter Last Name"),
      email: Yup.string()
      .required("Please Enter Email")
      .when([], {
        is: () => IsSameEmailCheck, // Only apply the test if IsSameEmailCheck is true
        then: (schema) =>
          schema.test(
            "email-not-match",
            "Email Can't be Same",
            (value) =>
              value?.toLowerCase() !== isNewParentDetail?.email?.toLowerCase()
          ),
      }),
      phone_no: Yup.number(),
    }),
    onSubmit: (values: ReferralCaseLoad) => {
      setIsNewParentDetail(values);
      let payload = {
        parent_detail: values,
        // isNewSchool: isNewSchool,
      };

      setIsEditParentDetail(false);
      if (!IsAddParentFlag) {
        if (payload.parent_detail.role === constant.ROLE.ALL_ACCESS) {
          // dispatch(updateDetailsByCaseload(caseloadId, payload));
          if (payload?.parent_detail?.id) {
            const propertiesToRemove = ["id", "scope", "role", "status"];
            const cleanedValues = Object.fromEntries(
              Object.entries(values).filter(
                ([key]) => !propertiesToRemove.includes(key)
              )
            );
            dispatch(
              updateOverViewParent(
                caseloadId,
                payload?.parent_detail?.id,
                cleanedValues
              )
            );
          }
        } else if (
          payload.parent_detail.role === constant.ROLE.RESTRICTED_ACCESS
        ) {
          payload.parent_detail = {
            ...payload.parent_detail,
            jobRole: "test",
          };
          const { status, role, scope, ...updatedParentDetail } =
            payload.parent_detail;
          payload.parent_detail = updatedParentDetail;
          dispatch(
            updateOtherParentById(updatedParentDetail, updatedParentDetail)
          );
          setTimeout(() => {
            dispatch(getCaseloadById(caseloadId));
          }, 100);
        }
      } else {
        let { isNewSchool, ...updatedParentDetail } = payload.parent_detail;
        dispatch(addOtherParentById(updatedParentDetail));
        setTimeout(() => {
          dispatch(getCaseloadById(caseloadId));
        }, 100);
      }
    },
  });

  const addClinician = () => {
    let tempClinicianList: any = [];
    let tempClinicianIds: any = [];
    if (isSeletedClinician) {
      tempClinicianList = tempClinicianList.concat(isSeletedClinician);
    }

    let Ids = isSeletedClinician?.map((item: any) => item?.value);
    if (Ids) {
      tempClinicianIds = tempClinicianIds.concat(Ids);
    }
    tempClinicianIds.filter(
      (v: any, i: any, a: any) => a.findIndex((v2: string) => v2 === v) === i
    );
    tempClinicianList.filter(
      (v: any, i: any, a: any) =>
        a.findIndex((v2: any) => v2.value === v.value) === i
    );
    setSelectClinicianList(tempClinicianList);
    setClinicianIds(tempClinicianIds);
    setIsAddClinicianModel(false);
    let payload = {
      clinician_ids: tempClinicianIds,
      isNewSchool: isNewSchool,
    };
    dispatch(updateDetailsByCaseload(caseloadId, payload));
  };

  const createCaseload = () => {
    let payload = {
      school_detail: isNewSchoolDetail,
      parent_detail: isNewParentDetail,
      digital_literacy: digital_literacy,
      additional_parent_carers_details: additional_parent_carers_details,
      isNewSchool: isNewSchool,
      patient_name: referralCaseload?.patient_detail?.name,
      patient_dob: referralCaseload?.patient_detail?.dob,
      nhs_number: referralCaseload?.patient_detail?.nhs_number,
      patient_gender: referralCaseload?.patient_detail?.gender,
      patient_email: referralCaseload?.patient_detail?.email,
      patient_address: referralCaseload?.patient_detail?.address,
      referral_id: params.id,
      gp_address: gp_details?.address,
      gp_name: gp_details?.name,
      clinician_ids: clinicianIds,
    };

    dispatch(addCaseload(payload, navigate));
  };

  const handleSelect = (e: any) => {
    setIsNewSchoolDetail(e);
    setIsSelectedSchoolDetail(e);
    setButtonEnabled(true);
  };

  const handleSchool = () => {
    let payload = {
      school_detail: isNewSchoolDetail,
      isNewSchool: isNewSchool,
    };
    dispatch(updateDetailsByCaseload(caseloadId, payload));
    setBackupSchoolDetail(isNewSchoolDetail);
    setBackupNewSchool(isNewSchool);
    setIsEditDetail(false);
  };

  const handleSetSchool = () => {
    setCurrentTab("one");
    validation.resetForm();
    setIsEditParentDetail(false);
    if (isBackupNewSchool) {
      validation.setValues(isBackupSchoolDetail);
    } else {
      setIsNewSchoolDetail(isBackupSchoolDetail);
    }
    setIsNewSchool(isBackupNewSchool);
    dispatch(getUserList({ scope: constant.SCOPE.SCHOOL }));
    setScope(constant.SCOPE.SCHOOL);
    setIsEditDetail(true);
  };

  useLayoutEffect(() => {
    if (isNewSchool === false) {
      setIsSelectedSchoolDetail(isNewSchoolDetail);
    } else {
      setIsSelectedSchoolDetail(isBackupSchoolDetail);
    }
  }, [isNewSchool]);

  const handleChange = (event: any) => {
    setCurrentTab(event.target.value);
    setIsNewSchool(event.target.value === "one");
  };

  const handleDeleteParent = (id: any) => {
    dispatch(deleteAdditonalParent(caseloadId, id));
    setKey((prevKey) => prevKey + 1);
    setisDeleteTask(false);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDataLoaded(true);
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);

  const handleSearchGp = (value: string) => {
    setUserAccess("ques22");
    setQuery(value);
  };

  const getMemoOptionsBySchool = useMemo(() => {
    if (query?.length >= 3 && schooList?.length > 0) {
      return schooList.map((items: any) => {
        return {
          ...items,
          label: `${items?.school_name} (${items?.address}), ${items?.phone_no}`,
        };
      });
    }
    return [];
  }, [schooList, query]);

  const sliderContent = useMemo(() => {
    return (
      <Slider
        key={key}
        showStatus={false}
        showIndicators={
          additional_parent_carers_details.length !== 0 ? true : false
        }
        showArrows={
          additional_parent_carers_details.length !== 0 ? true : false
        }
      >
        <CardContent>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            className={classes.clTitle}
          >
            <Typography variant="h6">1. Parent/Carer Details </Typography>

            {!refClosedStatus &&
              constant.SCOPE.CLINICIAN !== userLoggedData?.scope && (
                <Box>
                  {digital_literacy === false && (
                    <NoCellIcon
                      sx={{
                        cursor: "pointer",
                        color: "#6A2382",
                        marginRight: "1rem",
                      }}
                    />
                  )}
                  <AddCircleIcon
                    onClick={() => {
                      validationParent.resetForm();
                      setIsSameEmailCheck(true)
                      validationParent.setValues((prevValues) => ({
                        ...prevValues,
                        role: constant.ROLE.RESTRICTED_ACCESS,
                        isAdditionalParent: true,
                        caseload_id: caseloadId,
                        jobRole: "",
                        scope: constant.SCOPE.PARENT,
                        first_name: "",
                        last_name: "",
                        email: "",
                        postCode: "",
                        phone_no: "",
                      }));
                      setIsAddParentFlag(true);
                      setParentType('')
                      setIsEditParentDetail(true);
                      // dispatch(getUserList({ scope: constant.SCOPE.PARENT }));
                      setScope(constant.SCOPE.PARENT);
                    }}
                    sx={{
                      cursor: "pointer",
                      color: "#6A2382",
                      marginRight: "1rem",
                    }}
                  />
                  <ModeEditIcon
                    onClick={() => {
                      validationParent.resetForm();
                      setIsSameEmailCheck(false)
                      setIsMode(isNewParentDetail)
                      setParentType('MAIN')
                      validationParent.setValues(isNewParentDetail);
                      setIsEditParentDetail(true);
                      // dispatch(getUserList({ scope: constant.SCOPE.PARENT }));
                      setScope(constant.SCOPE.PARENT);
                    }}
                    sx={{ cursor: "pointer", color: "#6A2382" }}
                  />
                </Box>
              )}
          </Box>
          {/* <Divider /> */}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            my={"5px"}
          >
            <Typography variant="subtitle1">Name : </Typography>
            <Typography sx={style.font_custom_style}>
              {(isNewParentDetail?.first_name
                ? isNewParentDetail?.first_name
                : "") +
                " " +
                (isNewParentDetail?.last_name
                  ? isNewParentDetail?.last_name
                  : "")}
            </Typography>
          </Box>
          <Divider />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            my={"5px"}
          >
            <Typography variant="subtitle1">Email : </Typography>
            <Typography sx={style.font_custom_style}>
              {isNewParentDetail?.email}
            </Typography>
          </Box>
          <Divider />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            my={"5px"}
          >
            <Typography variant="subtitle1">Contact Number : </Typography>
            <Typography sx={style.font_custom_style}>
              {isNewParentDetail?.phone_no}
            </Typography>
          </Box>
        </CardContent>
        {additional_parent_carers_details &&
          additional_parent_carers_details.length !== 0 &&
          additional_parent_carers_details?.map &&
          additional_parent_carers_details.map((item: any, index: number) => (
            <CardContent key={item.id}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                className={classes.clTitle}
              >
                <Typography variant="h6">
                  {index + 1}. Additional Parent/Carer Details
                </Typography>

                <Box>
                  <ModeEditIcon
                    onClick={() => {
                      validationParent.resetForm();
                      validationParent.setValues(item.user);
                      setIsEditParentDetail(true);
                      setIsAddParentFlag(false);
                      setParentType('OTHER')
                      setIsMode(item.user)
                      // dispatch(getUserList({ scope: constant.SCOPE.PARENT }));
                      setScope(constant.SCOPE.PARENT);
                    }}
                    sx={{
                      cursor: "pointer",
                      color: "#6A2382",
                      marginRight: "1rem",
                    }}
                  />
                  <ModeDeleteIcon
                    onClick={() => {
                      setisDeleteTask(true);
                      setisDeleteId(item?.user.id);
                      // handleDeleteParent(item?.user.id)
                    }}
                    sx={{
                      cursor: "pointer",
                      color: "#6A2382",
                      marginRight: "10px",
                    }}
                  />
                </Box>
              </Box>
              <Divider />
              <Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  my={"5px"}
                >
                  <Typography variant="subtitle1">Name : </Typography>
                  <Typography sx={style.font_custom_style}>
                    {(item?.user?.first_name ? item?.user?.first_name : "") +
                      " " +
                      (item?.user?.last_name ? item?.user?.last_name : "")}
                  </Typography>
                </Box>
                <Divider />
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  my={"5px"}
                >
                  <Typography variant="subtitle1">Email : </Typography>
                  <Typography sx={style.font_custom_style}>
                    {item?.user?.email}
                  </Typography>
                </Box>
                <Divider />
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  my={"5px"}
                >
                  <Typography variant="subtitle1">Contact Number : </Typography>
                  <Typography sx={style.font_custom_style}>
                    {item?.user?.phone_no === "" ? "-" : item?.user?.phone_no}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          ))}
      </Slider>
    );
  }, [
    key,
    additional_parent_carers_details,
    isNewParentDetail,
    userLoggedData,
  ]);

  const educationDetails = useMemo(() => {
    return (
      <Card variant="outlined" sx={style.card_custom}>
        <CardContent>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            className={classes.clTitle}
            marginBottom={"1rem"}
          >
            <Grid
              sx={style.eduCard}
            >
              <Typography variant="h6" sx={{ mb: "0!important", mr: 2 }}>
                2. Education Setting Details
              </Typography>

              {isBackupSchoolDetail?.status ===
                constant.STATUS.NOT_REGISTERED && (
                <Typography
                  variant="caption"
                  sx={style.notRegister}
                >
                  Not Registered
                </Typography>
              )}
              {dataLoaded &&
                !isBackupSchoolDetail?.status &&
                !isHomeSchooling && (
                  <Typography
                    variant="caption"
                    sx={style.noAccess}
                  >
                    No Access
                  </Typography>
                )}
              {isHomeSchooling && (
                <Typography
                  component={"span"}
                  sx={style.homeSchool}
                >
                  (<HomeWorkIcon /> Elective Home Education / Not Currently in
                  Formal Education)
                </Typography>
              )}
            </Grid>
            {!refClosedStatus &&
              constant.SCOPE.CLINICIAN !== userLoggedData?.scope && (
                <AddCircleIcon
                  onClick={() => {
                    handleSetSchool();
                  }}
                  sx={{ cursor: "pointer", color: "#6A2382" }}
                />
              )}
          </Box>
          {/* <Divider /> */}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            my={"5px"}
          >
            <Typography variant="subtitle1">Education Name: </Typography>
            <Typography sx={style.font_custom_style}>
              {isBackupSchoolDetail?.school_name || isBackupSchoolDetail?.label}
            </Typography>
          </Box>
          <Divider />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            my={"5px"}
          >
            <Typography variant="subtitle1">Contact Person: </Typography>
            <Typography sx={style.font_custom_style}>
              {" "}
              {isBackupSchoolDetail?.contact_person_name ||
              isBackupSchoolDetail?.first_name
                ? isBackupSchoolDetail?.first_name +
                  " " +
                  isBackupSchoolDetail?.last_name
                : ""}
            </Typography>
          </Box>
          <Divider />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            my={"5px"}
          >
            <Typography variant="subtitle1">Contact Email: </Typography>
            <Typography sx={style.font_custom_style}>
              {" "}
              {isBackupSchoolDetail?.email}
            </Typography>
          </Box>
          <Divider />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            my={"5px"}
          >
            <Typography variant="subtitle1">Contact Number: </Typography>
            <Typography sx={style.font_custom_style}>
              {isBackupSchoolDetail?.phone_no}
            </Typography>
          </Box>
          <Divider />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            my={"5px"}
          >
            <Typography variant="subtitle1">Address: </Typography>
            <Typography sx={style.font_custom_style}>
              {isBackupSchoolDetail?.address}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    );
  }, [isBackupSchoolDetail, isHomeSchooling, userLoggedData]);

  const clinicianDetails = useMemo(() => {
    return (
      <Card variant="outlined" sx={style.card_custom}>
        <CardContent>
          {!refClosedStatus &&
            constant.SCOPE.CLINICIAN !== userLoggedData?.scope && (
              <Grid
                sx={style.clinicianTab}
                className="innerheader"
              >
                <Grid>
                  <Typography variant="h6">Assign Clinicians</Typography>
                </Grid>
                <Grid>
                  <Button
                    variant="outlined"
                    className="  btnStyle_v2"
                    startIcon={<AddCircleIcon />}
                    sx={{ borderRadius: "60px" }}
                    onClick={() => {
                      dispatch(
                        getUserList({ scope: constant.SCOPE.CLINICIAN })
                      );
                      setScope(constant.SCOPE.CLINICIAN);
                      setIsAddClinicianModel(true);
                    }}
                  >
                    Add Clinician
                  </Button>
                </Grid>
              </Grid>
            )}
          {!refClosedStatus && <Divider />}
          <Grid
            sx={style.clinicalTable}
          >
            <Table
              className={classes.customFixeHeight}
              data={
                selectClinicianList &&
                selectClinicianList?.map((item: any, index: number) => {
                  return {
                    ...item,
                    // action: <HighlightOffIcon onClick={() => removeClinician(index)} sx={{ color: '#F15046', cursor: 'pointer' }} />
                  };
                })
              }
              columns={columns}
            />
          </Grid>
        </CardContent>
      </Card>
    );
  }, [selectClinicianList, userLoggedData]);

  return (
    <Grid className={classes.clWrapper}>
      <Box
        sx={{
          borderRadius: "10px",
          mt: firstSegment !== "manage-caseload" ? 3 : 0,
        }}
      >
        <Grid
          container
          columns={12}
          sx={style.cardGrid}
        >
          <Grid item xs={12} md={6} sx={{ mb: "1rem!important" }}>
            {/* Parent/Carer Details Card */}
            <Card variant="outlined" sx={style.card_custom}>
              {sliderContent}
            </Card>
          </Grid>

          <Grid item xs={12} md={6} sx={{ mb: "1rem!important" }}>
            {educationDetails}
          </Grid>
        </Grid>
        <Grid sx={{ py: { md: "20px" } }}>{clinicianDetails}</Grid>
      </Box>

      <>
        <Modal
          open={isAddClinicianModel}
          handleClose={() => {
            setIsAddClinicianModel(false);
            // setIsSeletedClinician('')
          }}
          title={"Add Clinicians"}
        >
          <Box className={classes.clinicModal}>
            <Grid sx={{ width: "100%" }}>
              <Select
                placeholder={
                  isSeletedClinician.length === 0 ? "Select Clinicians" : ""
                }
                onInputChange={(e: any) => e}
                isMulti={true}
                disableCloseOnSelect={true}
                label={"Clinicians"}
                defaultValue={isSeletedClinician}
                options={userList}
                onChange={(e: any) => setIsSeletedClinician(e)}
              />
            </Grid>
            <Grid
              className={classes.btnWrapper}
              sx={style.addBtnWrapper}
            >
              <Button variant={"contained"} onClick={() => addClinician()}>
                Add Clinicians
              </Button>
            </Grid>
          </Box>
        </Modal>
      </>

      <>
        <ConfirmationModal
          open={isCreateCaseload}
          handleClose={() => setIsCreateCaseload(false)}
          title="Confirmation"
          message="Are you sure you want to accept Referral ?"
          onConfirm={() => createCaseload()}
          onCancel={() => setIsCreateCaseload(false)}
        />
      </>

      {isDeleteTask && (
        <ConfirmationModal
          open={isDeleteTask}
          handleClose={() => setisDeleteTask(false)}
          title="Confirmation"
          message="Are you sure you want to delete additional Parent ?"
          onConfirm={() => handleDeleteParent(isDeleteId)}
          onCancel={() => setisDeleteTask(false)}
        />
      )}

      <>
        <LightBox
          title={"Assign School"}
          open={isEditDetail}
          handleClose={() => {
            setIsEditDetail(false);
          }}
        >
          <Box className={classes.clinicModal}>
            <Grid sx={{ width: "100%", textAlign: "center" }}></Grid>
            <Grid className={classes.btnWrapper} sx={{ p: "" }}>
              <Grid>
                <form onSubmit={validation.handleSubmit}>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <RadioGroup
                      value={currentTab}
                      onChange={handleChange}
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="one"
                        control={<Radio />}
                        label="New School"
                        style={{ marginBottom: 0 }}
                        sx={isNewSchool ? style.label_color : {}}
                      />
                      <FormControlLabel
                        value="two"
                        control={<Radio />}
                        label="Existing School"
                        style={{ marginBottom: 0 }}
                        sx={!isNewSchool ? style.label_color : {}}
                      />
                    </RadioGroup>
                  </Box>
                  {currentTab === "one" && (
                    <>
                      <Grid container spacing={3} className={classes.mt_3}>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                          <Box>
                            <InputLabel sx={style.label_color}>
                              Contact Person First Name
                            </InputLabel>
                            <TextField
                              fullWidth
                              placeholder="Enter First Name"
                              {...validation.getFieldProps("first_name")}
                              error={
                                validation.touched.first_name &&
                                Boolean(validation.errors.first_name)
                              }
                              helperText={
                                validation.touched.first_name
                                  ? validation.errors.first_name
                                  : ""
                              }
                              // disabled={!isNewSchool}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                          <Box>
                            <InputLabel sx={style.label_color}>
                              Contact Person Last Name
                            </InputLabel>
                            <TextField
                              fullWidth
                              placeholder="Enter Last Name"
                              {...validation.getFieldProps("last_name")}
                              error={
                                validation.touched.last_name &&
                                Boolean(validation.errors.last_name)
                              }
                              helperText={
                                validation.touched.last_name &&
                                validation.errors.last_name
                              }
                              // disabled={!isNewSchool}
                            />
                          </Box>
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        spacing={3}
                        className={classes.mt_3}
                        sx={{ marginBottom: "2rem" }}
                      >
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                          <Box>
                            <InputLabel sx={style.label_color}>
                              School Name
                            </InputLabel>
                            <TextField
                              fullWidth
                              type={"text"}
                              placeholder="Enter School Name"
                              {...validation.getFieldProps("school_name")}
                              error={
                                validation.touched.school_name &&
                                Boolean(validation.errors.school_name)
                              }
                              helperText={
                                validation.touched.school_name &&
                                validation.errors.school_name
                              }
                              // disabled={!isNewSchool}
                            />
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} lg={6}>
                          <Box>
                            <InputLabel sx={style.label_color}>
                              Email
                            </InputLabel>
                            <TextField
                              fullWidth
                              type={"email"}
                              placeholder="Enter Email"
                              {...validation.getFieldProps("email")}
                              error={
                                validation.touched.email &&
                                Boolean(validation.errors.email)
                              }
                              helperText={
                                validation.touched.email &&
                                validation.errors.email
                              }
                              // disabled={!isNewSchool}
                            />
                          </Box>
                        </Grid>
                      </Grid>

                      <Grid className={classes.mt_3}>
                        <Box>
                          <InputLabel sx={style.label_color}>
                            Address
                          </InputLabel>
                          <TextField
                            fullWidth
                            type="text"
                            placeholder="Enter Address"
                            {...validation.getFieldProps("address")}
                            error={
                              validation.touched.address &&
                              Boolean(validation.errors.address)
                            }
                            helperText={
                              validation.touched.address &&
                              validation.errors.address
                                ? String(validation.errors.address) // Convert errors to string
                                : "" // Empty string if there are no errors
                            }
                            rows={3}
                            // disabled={!isNewSchool}
                            multiline={true}
                            inputProps={{ maxLength: 500 }}
                          />
                        </Box>
                      </Grid>

                      <Grid container spacing={3} className={classes.mt_3}>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                          <Box>
                            <InputLabel sx={style.label_color}>
                              Contact Person Mobile
                            </InputLabel>
                            <TextField
                              fullWidth
                              type="number"
                              placeholder="Enter mobile"
                              {...validation.getFieldProps("phone_no")}
                              error={
                                validation.touched.phone_no &&
                                Boolean(validation.errors.phone_no)
                              }
                              helperText={
                                validation.touched.phone_no &&
                                validation.errors.phone_no
                              }
                              // disabled={!isNewSchool}
                            />
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} lg={6}>
                          <Box>
                            <InputLabel sx={style.label_color}>
                              Postcode
                            </InputLabel>
                            <TextField
                              fullWidth
                              type="text"
                              placeholder="Enter Postcode"
                              {...validation.getFieldProps("postCode")}
                              error={
                                validation.touched.postCode &&
                                Boolean(validation.errors.postCode)
                              }
                              helperText={
                                validation.touched.postCode &&
                                validation.errors.postCode
                              }
                              // disabled={!isNewSchool}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </>
                  )}

                  {currentTab === "two" && (
                    <Grid width={"100%"} className={classes.mt_3}>
                      <InputLabel sx={style.label_color}>School</InputLabel>
                      <Select
                        placeholder={"Enter atleast 3 characters"}
                        onInputChange={(e: any) => handleSearchGp(e)}
                        isDisabled={isNewSchool}
                        disableCloseOnSelect={false}
                        defaultValue={isSelectedSchoolDetail?.school_name}
                        options={[placeholderObject, ...getMemoOptionsBySchool]}
                        onChange={(e: any) => handleSelect(e)}
                      />
                    </Grid>
                  )}
                  <Grid
                    className={`${classes.btnClr}`}
                    sx={{ mt: "30px", textAlign: "center" }}
                  >
                    {currentTab !== "two" ? (
                      <Button
                        type={"submit"}
                        fullWidth={false}
                        variant={"contained"}
                      >
                        Add & Assign
                      </Button>
                    ) : (
                      isButtonEnabled &&
                      isSelectedSchoolDetail && (
                        <Button
                          fullWidth={false}
                          variant={"contained"}
                          onClick={() => handleSchool()}
                        >
                          Assign
                        </Button>
                      )
                    )}
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </Box>
        </LightBox>
      </>
      <>
        {
          <LightBox
            title={IsAddParentFlag ? "Add Additional Parent" : parentType === 'MAIN' ? 'Edit Parent Details' : parentType === 'OTHER' ? 'Edit Additional Parent Details'  : "Assign Parent"}
            open={isEditParentDetail}
            handleClose={() => {
              validationParent.resetForm();
              setIsEditParentDetail(false);
              setIsAddParentFlag(false);
            }}
          >
            <Box className={classes.clinicModal}>
              <Grid sx={{ width: "100%", textAlign: "center" }}></Grid>
              <Grid className={classes.btnWrapper} sx={{ p: "" }}>
                <Grid>
                  <form onSubmit={validationParent.handleSubmit}>
                    <Grid container spacing={3} className={classes.mt_3}>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Box>
                          <InputLabel sx={style.label_color}>
                            {" "}
                            First Name{" "}
                            <Typography
                              variant="body2"
                              component={"span"}
                              sx={{ color: "#d32f2f", fontWeight: "600" }}
                            >
                              *
                            </Typography>
                          </InputLabel>
                          <TextField
                            fullWidth
                            placeholder="Enter First Name"
                            {...validationParent.getFieldProps("first_name")}
                            error={
                              validationParent.touched.first_name &&
                              Boolean(validationParent.errors.first_name)
                            }
                            helperText={
                              validationParent.touched.first_name
                                ? validationParent.errors.first_name
                                : ""
                            }
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Box>
                          <InputLabel sx={style.label_color}>
                            {" "}
                            Last Name{" "}
                            <Typography
                              variant="body2"
                              component={"span"}
                              sx={{ color: "#d32f2f", fontWeight: "600" }}
                            >
                              *
                            </Typography>
                          </InputLabel>
                          <TextField
                            fullWidth
                            placeholder="Enter Last Name"
                            {...validationParent.getFieldProps("last_name")}
                            error={
                              validationParent.touched.last_name &&
                              Boolean(validationParent.errors.last_name)
                            }
                            helperText={
                              validationParent.touched.last_name &&
                              validationParent.errors.last_name
                            }
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} className={classes.mt_3}>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Box>
                          <InputLabel sx={style.label_color}>
                            Email{" "}
                            <Typography
                              variant="body2"
                              component={"span"}
                              sx={{ color: "#d32f2f", fontWeight: "600" }}
                            >
                              *
                            </Typography>
                          </InputLabel>
                          <TextField
                            fullWidth
                            type={"email"}
                            disabled={
                              !IsAddParentFlag &&
                              (
                                // isMode?.status === constant.STATUS.NOT_REGISTERED 
                                // ||  
                                isMode?.status === constant.STATUS.ACTIVE
                                )
                            }
                            placeholder="Enter Email"
                            {...validationParent.getFieldProps("email")}
                            error={
                              validationParent.touched.email &&
                              Boolean(validationParent.errors.email)
                            }
                            helperText={
                              validationParent.touched.email &&
                              validationParent.errors.email
                            }
                          />
                          {!IsAddParentFlag && isMode?.status ===
                            constant.STATUS.ACTIVE && (
                            <Box sx={{ color: "#6A2382", fontSize: "14px" }}>
                              User Already Registered
                            </Box>
                          )}
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Box>
                          <InputLabel sx={style.label_color}>Mobile</InputLabel>
                          <TextField
                            fullWidth
                            type="number"
                            placeholder="Enter mobile"
                            {...validationParent.getFieldProps("phone_no")}
                            error={
                              validationParent.touched.phone_no &&
                              Boolean(validationParent.errors.phone_no)
                            }
                            helperText={
                              validationParent.touched.phone_no &&
                              validationParent.errors.phone_no
                            }
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid
                      className={`${classes.btnClr}`}
                      sx={{ mt: "30px", textAlign: "center" }}
                    >
                      <Button
                        type={"submit"}
                        fullWidth={false}
                        variant={"contained"}
                      >
                       {(parentType === 'MAIN' || parentType === 'OTHER') ? 'Edit' : 'Assign'}
                      </Button>
                    </Grid>
                  </form>
                </Grid>
              </Grid>
            </Box>
          </LightBox>
        }
      </>
    </Grid>
  );
};

export default memo(OverviewCaseload);
