import  { useState } from 'react'
import { Box, Dialog } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';


export default function CustomDatePicker({openDatePicker,setOpenDatePicker,setMonth}:any) {
    const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | null>(null);

    const handleDateChange = (date: dayjs.Dayjs | null) => {
        if (date) {
          const monthsDiff = dayjs().diff(date, "month");
          setMonth(monthsDiff.toString())
          setSelectedDate(date);
        }
        setOpenDatePicker(false);
      };

      
  return (
    <Dialog open={openDatePicker} onClose={() => setOpenDatePicker(false)}>
    <Box p={2}>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        value={selectedDate}
        onChange={handleDateChange}
        disableFuture
      />
      </LocalizationProvider>
    </Box>
  </Dialog>
  )
}
