import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
  CartesianGrid,
} from "recharts";
import {
  Box,
  Typography,
  Card,
  CardContent,
  FormControl,
  MenuItem,
  Chip,
  Select,
  Checkbox,
} from "@mui/material";
import { useMemo, useRef, useState } from "react";
import ActionGroup from "./ActionGroup";
import constant from "../../../constants/constant";
import GraphTable from "./GraphTable";
import html2canvas from "html2canvas";
import { styles } from "./Styles";

interface OptionType {
  label: string;
  value: string;
}

export const StackedChartCard = ({
  title,
  data,
  keys,
  colors,
  options,
  handleOptionSelection,
  setMonth,
  month,
  setName,
  name,
  setSelectedFilter,
  setSelectedfilteredInitialDataFilter,
  setOpenDatePicker,
  setSelectedfilteredOutcomeDataFilter,
}: any) => {
  const [selectedOption, setSelectedOption] = useState<OptionType[]>([]);
  const chartRef = useRef<HTMLDivElement>(null);
  const [activeTab, setActiveTab] = useState<{ name: string; value: any }>({
    name: name,
    value: "12",
  });
  const handleChange = (ev: any) => {
    setSelectedOption(ev.target.value);
    setName(name);
    handleOptionSelection(ev.target.value, name);
  };

  const totals = useMemo(() => {
    return data?.reduce((acc: any, item: any) => {
      keys.forEach((key: string) => {
        acc[key] = (acc[key] || 0) + (item[key] || 0);
      });
      return acc;
    }, {});
  }, [data, keys]);

  const handleDelete = (value: string) => {
    setSelectedOption((prev) => prev.filter((item: any) => item !== value));

    const filterActions = {
      [constant.FILTERS_NAME.percentageOfReferralsMdtAcceptedOrRejected]: () =>
        setSelectedfilteredOutcomeDataFilter((prev: any) =>
          prev.filter((item: any) => item !== value)
        ),
      [constant.FILTERS_NAME.initiallyAcceptedAndRejectedCount]: () =>
        setSelectedfilteredInitialDataFilter((prev: any) =>
          prev.filter((item: any) => item !== value)
        ),
      default: () =>
        setSelectedFilter((prev: any) =>
          prev.filter((item: any) => item !== value)
        ),
    };

    (filterActions[name] || filterActions.default)();
  };

  const handleMonthChange = (
    _event: React.MouseEvent<HTMLElement>,
    newValue: string | null
  ) => {
    if (newValue === "custom") {
      setOpenDatePicker(true);
      setName(name);
    } else {
      setMonth(newValue);
      setName(name);
    }
    setActiveTab({ value: newValue, name: name });
  };

  const handleDownload = async () => {
    if (chartRef.current) {
      const canvas = await html2canvas(chartRef.current, {
        backgroundColor: "#fff",
      });
      const image = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = image;
      link.download = `${title}.png`;
      link.click();
    }
  };

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: "rgba(30, 27, 57, 1)",
            color: "#fff",
            padding: "10px",
            borderRadius: "8px",
            position: "relative",
            boxShadow: "0px 4px 8px rgba(0,0,0,0.2)"
          }}
        >
          {/* Arrow */}
          <div
            style={{
              position: "absolute",
              bottom: "-10px",
              left: "50%",
              transform: "translateX(-50%)",
              width: "0",
              height: "0",
              borderLeft: "8px solid transparent",
              borderRight: "8px solid transparent",
              borderTop: "10px solid rgba(30, 27, 57, 1)"
            }}
          ></div>
  
          {/* Tooltip Content */}
          {payload.map((entry: any, index: number) => (
            <p key={index} style={{ color: "#fff", margin: 0 }}>
              {entry.name}: {entry.value.toLocaleString()}
            </p>
          ))}
        </div>
      );
    }
  
    return null;
  };

  return (
    <Card sx={styles.graphCard} ref={chartRef}>
      <CardContent sx={{ p: 0 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 5 }}>
          <Box>
            <Typography
              component={"small"}
              gutterBottom
              color={"#9291A5"}
              fontFamily={"poppins"}
            >
              Statistics
            </Typography>

            <Typography
              fontWeight="bold"
              gutterBottom
              fontSize={"22px"}
              color={"#1E1B39"}
            >
              {title}
            </Typography>
          </Box>
          <ActionGroup
            name={name}
            value={month}
            onChange={handleMonthChange}
            onDownload={handleDownload}
            isActiveTab={activeTab}
          />
        </Box>
        {options && (
          <Box sx={{ display: "flex", mb: 5 }}>
            <div
              style={{
                flexGrow: "1",
                display: "flex",
                gap: "1rem",
                flexWrap: "wrap",
              }}
            >
              {selectedOption.map((value: any) => {
                const selectedOption = options?.find(
                  (option: any) => option?.value === value
                );
                return (
                  <Chip
                    key={value}
                    label={selectedOption?.label}
                    onDelete={() => handleDelete(value)}
                    sx={{ backgroundColor: "#fafafa", color: "#2e2e2e" }}
                  />
                );
              })}
            </div>
            <div style={{ maxWidth: "250px", minWidth: "250px" }}>
              <FormControl fullWidth>
                <Select
                  multiple
                  value={selectedOption}
                  onChange={handleChange}
                  displayEmpty
                  renderValue={(selected) => {
                    return <>Select Filter</>;
                  }}
                >
                  {options?.map((option: any) => (
                    <MenuItem key={option.value} value={option.value}>
                      <Checkbox
                        checked={selectedOption.includes(option.value)}
                      />
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </Box>
        )}

        <ResponsiveContainer width="100%" height={450}>
          <BarChart data={data} stackOffset="expand">
            <CartesianGrid strokeDasharray="5" vertical={false} stroke="rgba(229, 229, 239, 1)"/>
            <XAxis dataKey="name" />
            <YAxis tickFormatter={(value) => `${(value * 100).toFixed(0)}%`} />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            {keys.map((key: string, index: number) => (
              <Bar
                key={key}
                dataKey={key}
                stackId="a"
                fill={colors[index]}
                barSize={50}
                radius={[5, 5, 0, 0]}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
        <GraphTable keys={keys} totals={totals} />
      </CardContent>
    </Card>
  );
};
