import constant from "../../../constants/constant";

interface OptionType {
    label: string;
    value: string;
  }

export const reasonOptions: OptionType[] = [
    { value: "1", label: constant?.CLINICAL_REVIEW?.[1] },
    { value: "2", label: constant?.CLINICAL_REVIEW?.[2] },
    { value: "3", label: constant?.CLINICAL_REVIEW?.[3] },
    { value: "4", label: constant?.CLINICAL_REVIEW?.[4] },
    { value: "5", label: constant?.CLINICAL_REVIEW?.[5] },
    { value: "6", label: constant?.CLINICAL_REVIEW?.[6] },
    { value: "7", label: constant?.CLINICAL_REVIEW?.[7] },
    { value: "8", label: constant?.CLINICAL_REVIEW?.[8] },
    { value: "9", label: constant?.CLINICAL_REVIEW?.[9] },
  ];
  
  export const sourceOptions: OptionType[] = [
    { value: constant.SCOPE.CLIENT_ADMIN, label: 'Admin' },
    { value: constant.SCOPE.SCHOOL, label: 'School' },
    { value: constant.SCOPE.UNKNOWN, label: 'Open Url' },
  ];
  
  export const outcomeOptions: OptionType[] = [
    { value: constant.GRAPH_STATUS.ACCEPTED, label: 'Accepted' },
    { value: constant.GRAPH_STATUS.REJECTED, label: 'Rejected' },
  ];
  
  export const outcomeKeyMappings: Record<string, string> = {
    '1': 'accept',
    '2': 'reject',
  };
  
  export const initialKeyMappings: Record<string, string> = {
    '1': 'accepted_percentage',
    '2': 'rejected_percentage',
  };