import {
  ACTIVE_DATE_FILTER,
  CASELOAD_CURRENT_TAB,
    CASELOAD_END_DATE,
    CASELOAD_FILTER_OBJECT,
    CASELOAD_START_DATE,
    CHANGE_TAB,
    CHANGE_TAB_CASELOAD,
    LOADER_PDF,
    OPEN_REF_URL,
    REFFERAL_CLOSED,
    REF_CURRENT_TAB,
    RESET_PAGINATION,
    SET_CONVERSATION_ID,
    SET_DOCUMENT_TAB,
    SET_SELECTED_TAB,
    SET_SELECTED_USER,
    SET_TASK_TAB,
    SET_USER_LIST_ACTIVE
} from './actionType'

export const changeTab = (tab: string) => {
    return {
        type: CHANGE_TAB,
        tab
    }
}
export const SetActiveTab = (tab: number) => {
  return {
      type: CHANGE_TAB_CASELOAD,
      tab
  }
}

export const ResetPagination = (tab: boolean) => {
  return {
      type: RESET_PAGINATION,
      tab
  }
}

export const setSelectedTab = (tabIndex: number) => ({
    type: SET_SELECTED_TAB,
    payload: tabIndex
  });

  export const setSelectedDocTab = (tabIndex: number) => ({
    type: SET_DOCUMENT_TAB,
    payload: tabIndex
  });

  export const setSelectedTaskTab = (tabIndex: number) => ({
    type: SET_TASK_TAB,
    payload: tabIndex
  });

  export const setSelectedUser = (userId: number | null) => ({
    type: SET_SELECTED_USER,
    payload: userId
  });
  

  export const setConversationId = (conversationId: number | null) => ({
    
    type: SET_CONVERSATION_ID,
    payload: conversationId
  });

  export const setUserListActive = (userListActive:object) => ({
    type: SET_USER_LIST_ACTIVE,
    payload: userListActive
  });


  export const setRefClosedStatus = (status:boolean) => ({
    type: REFFERAL_CLOSED,
    payload: status
  });

  export const setOpenReferralUrl = (status:boolean) => ({
    type: OPEN_REF_URL,
    payload: status
  });

  export const setPdfLoader = (status:boolean) => ({
    type: LOADER_PDF,
    payload: status
  });

  export const setCurentTab = (status:number) => ({
    type: REF_CURRENT_TAB,
    payload: status
  });

  export const setCaseloadCurentTab = (status:number) => ({
    type: CASELOAD_CURRENT_TAB,
    payload: status
  });

  export const setIsfilterObject = (data:any) => ({
    type: CASELOAD_FILTER_OBJECT,
    payload: data
  });

  export const setIsStartDate = (data:any) => ({
    type: CASELOAD_START_DATE,
    payload: data
  });

  export const setIsEndDate = (data:any) => ({
    type: CASELOAD_END_DATE,
    payload: data
  });

  export const setActiveDateFilter = (data:any) => ({
    type: ACTIVE_DATE_FILTER,
    payload: data
  });




