import React from 'react'
import { Navigate } from 'react-router-dom'
import privatePages from '../pages/App'
import Auth from '../pages/Auth'
import { allScope } from '../constants/defaultValues'
import Openpdf from '../pages/App/ManageCaseLoad/Openpdf'
import Draft from '../pages/App/ManageCaseLoad/draft'
import ReportCharts from '../pages/App/ReportsCharts'
const { Resources, Dashboard,AddReport,SchoolReport, Referral, AdminAcounts, AddReferral, AuditTrail,Listing, Caseload, CreateCaseload, ReferralCaseload, ReferralView,RedFlag } = privatePages
const { Login, ForgotPassword, OTP, Register,Profile,ReferralSubmit ,RejectedReferral,PrivacyNotice} = Auth

const privateRoutes = [
    { path: '/dashboard', component: <Dashboard />, scope: allScope },
    { path: '/referral', component: <Referral />, scope: allScope },
    { path: '/referral/add', component: <AddReferral />, scope: allScope },
    { path: '/referral/edit/:id', component: <AddReferral />, scope: allScope },
    { path: '/referral/update/:id', component: <AddReferral />, scope: allScope },
    { path: '/report/add/:id', component: <AddReport />, scope: allScope  },
    { path: '/report/edit/:id/:id', component: <AddReport />, scope: allScope  },
    { path: '/report/school-edit/:id/:id', component: <SchoolReport />, scope: allScope  },
    { path: '/user-management/admin/add', component: <AdminAcounts />, scope:  allScope },
    { path: '/user-management/admin/edit/:id', component: <AdminAcounts />, scope:  allScope  },
    { path: '/user-management/clinician/edit/:id', component: <AdminAcounts />, scope: allScope },
    { path: '/user-management/clinician/add', component: <AdminAcounts />, scope: allScope },
    { path: '/profile', component: <Profile />, scope: allScope },
    { path: '/user-management/parent/edit/:id', component: <AdminAcounts />, scope: allScope },
    { path: '/user-management/parent/add', component: <AdminAcounts />, scope: allScope },
    { path: '/user-management/parent', component: <Listing />, scope: allScope },
    { path: '/user-management/education_setting/edit/:id', component: <AdminAcounts />, scope: allScope },
    { path: '/user-management/education_setting/add', component: <AdminAcounts />, scope: allScope },
    { path: '/user-management/education_setting', component: <Listing />, scope: allScope },
    { path: '/user-management/admin', component: <Listing />, scope:  allScope  },
    { path: '/user-management/clinician', component: <Listing />, scope: allScope },
    { path: '/manage-caseload/active', component: <Caseload />, scope: allScope },
    { path: '/manage-caseload/red-flag', component: <RedFlag />, scope: allScope },
    { path: '/manage-caseload/draft', component: <Draft />, scope: allScope },
    { path: '/manage-caseload/closed', component: <Caseload />, scope: allScope },
    { path: '/manage-caseload/rejected', component: <Caseload />, scope: allScope },
    { path: '/manage-caseload/create', component: <CreateCaseload />, scope: allScope },
    { path: '/referral/create-caseload/:id', component: <ReferralCaseload />, scope: allScope },
    { path: '/referral/view/:id', component: <ReferralView />, scope: allScope },
    { path: '/resources/add-content', component: <Resources />, scope: allScope },
    { path: '/resources/upload-Assets', component: <Resources />, scope: allScope },
    { path: '/audit-trail/:id', component: <AuditTrail />, scope: allScope },
    { path: '/reports', component: <ReportCharts />, scope: allScope },
]

const publicRoutes = [
    { path: "/", exact: true, component: <Navigate to="/login" />, },
    { path: '/login', component: <Login /> },
    { path: '/success', component: <ReferralSubmit /> },
    { path: '/referral-form', component: <AddReferral /> },
    { path: '/parent-report/:id', component: <Openpdf />},
    { path: '/education-report/:id', component: <Openpdf />},
    { path: '/clinician/login', component: <Login /> },
    { path: '/forgot-password', component: <ForgotPassword /> },
    { path: '/otp', component: <OTP /> },
    { path: '/register', component: <Register /> },
    { path: '/rejected', component: <RejectedReferral /> },
    { path: '/privacy-notice', component: <PrivacyNotice /> },
]
export { privateRoutes, publicRoutes }