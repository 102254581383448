
import {
  Box,
  Typography,
} from "@mui/material";
import { styles } from "./Styles";
import ActionGroup from "./ActionGroup";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const ChartHeader = ({ setMonth, month,setOpenDatePicker,handleClick,name,setName }: any) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<{ name: string; value: any }>({
    name: "header",
    value: "12",
  });
  const handleChange = (
    _event: React.MouseEvent<HTMLElement>,
    newValue: string | null
  ) => {
   if (newValue === "custom"){
      setOpenDatePicker(true);
    }else{
      setMonth(newValue);
    }
    setName('')
    setActiveTab({value:newValue,name:'header'})
  };

  const handleDownload = () => {
    handleClick()
  };
  const handleClose = () => {
    navigate('../dashboard')
  };

  return (
    <Box sx={styles.chartHeaderTitle}>
      <Typography variant="h5" fontWeight="bold" gutterBottom>
        My Care Bridge Analyzer
      </Typography>

      <ActionGroup
        name={'header'}
        value={month}
        onChange={handleChange}
        onDownload={handleDownload}
        onClose={handleClose}
        isActiveTab={activeTab}
      />
    </Box>
  );
};
