import React, { Suspense, memo, useEffect, useState } from "react";
import {
  Grid,
  Box,
  Tab,
  Tabs,
} from "@mui/material";
import classes from "./index.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedDocTab } from "../../../redux/Layout/action";
import { caseDocumentsById } from "../../../redux/action";
import { useLocation } from "react-router-dom";

const FileUploadList = React.lazy(() => import('./FileUploadList'));
const CaseDocuments = (props: any) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const activeTab = useSelector((state: any) => state.selectedDocTabReducer);
  const pathParts = location.pathname.split("/").filter(Boolean);
  const param = pathParts[0];

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(setSelectedDocTab(newValue));
  };

  useEffect(()=>{
    if(param !== 'resources' && props.hideUploadOption !== true){
      dispatch(caseDocumentsById(props._caseLoadId, 'navigate'));
    }
  },[param])

  return (
    <Grid>
      <Box>
        <Grid container columns={12} >
          <Grid item xs={12} md={12}>
            <Box
              sx={{ width: "100%", justifyContent: "space-between", display: "flex", borderBottom: '1px solid #CCCCCC', margin: '1rem 0rem' }}
            >
              <Tabs
                value={activeTab}
                onChange={handleChange}
                className={`${classes.tabContainer} ${classes.childStep}`}
                textColor="secondary"
                variant="fullWidth"
                indicatorColor="secondary"
                aria-label="secondary tabs example"
              >
                <Tab value={0} label="Documents" />
                <Tab value={1} label="Videos" />
              </Tabs>
            </Box>

            {[0, 1].map((index) => (
              <Grid
                role="tabpanel"
                hidden={activeTab !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                key={index}
              >
                <Suspense fallback={<div>Loading...</div>}>
                  <FileUploadList
                    uploadType={index === 0 ? 'document' : 'video'}
                    uploadCaseId={props._caseLoadId}
                    accept={index === 0 ? '.jpg,.jpeg,.png,.pdf' : '.mp4, .3gp, .mov, .avi, .wmv, .flv, .mkv, .webm, .mpeg, .mpg, .m4v, .ogv'}
                    resourceData={props.data}
                    patient={props.patient}
                    addResourceBtn={props.addResourceBtn}
                    hideUploadOption={props.hideUploadOption}
                    onPageCountChange={props.onPageCountChange}
                    onLimitCountChange={props.onLimitCountChange}
                    onLimitSearchChange={props.onLimitSearchChange}
                  />
                </Suspense>
              </Grid>
            ))}

          </Grid>
        </Grid>
      </Box>
    </Grid>
  )
};
export default memo(CaseDocuments);
