import React from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const GraphTable = ({ keys, totals }: any) => {
  const totalSum = (Object.values(totals) as number[]).reduce((sum, value) => sum + value, 0) || 1;

  return (
    <Box sx={{ mt: 2 }}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontFamily: 'Poppins', fontWeight: 600 }}>Result</TableCell>
              <TableCell sx={{ fontFamily: 'Poppins', fontWeight: 600 }}>Rejected In (no.)</TableCell>
              <TableCell sx={{ fontFamily: 'Poppins', fontWeight: 600 }}>Rejected In (%)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {keys.map((key: any) => (
              <TableRow key={key}>
                <TableCell sx={{ fontFamily: 'Poppins', textTransform: 'capitalize' }}>{key}</TableCell>
                <TableCell sx={{ fontFamily: 'Poppins', textTransform: 'capitalize' }}>{totals[key]}</TableCell>
                <TableCell sx={{ fontFamily: 'Poppins', textTransform: 'capitalize' }}>
                  {((totals[key] / totalSum) * 100).toFixed(2)}%
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default GraphTable;
