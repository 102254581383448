import { RESPONSE_CHATMSG_CLEAR } from "../caseload/actionType";
import {
  ACTIVE_DATE_FILTER,
  CASELOAD_CURRENT_TAB,
  CASELOAD_END_DATE,
  CASELOAD_FILTER_OBJECT,
  CASELOAD_START_DATE,
  CHANGE_TAB,
  CHANGE_TAB_CASELOAD,
  LOADER_PDF,
  OPEN_REF_URL,
  REFFERAL_CLOSED,
  REF_CURRENT_TAB,
  RESET_PAGINATION,
  SET_CONVERSATION_ID,
  SET_DOCUMENT_TAB,
  SET_SELECTED_TAB,
  SET_SELECTED_USER,
  SET_TASK_TAB,
  SET_USER_LIST_ACTIVE,
} from "./actionType";

let initialValues = {
  currentTab: "",
  openurlposL: "",
  loaderPdf: false,
  currentTabRef: 0,
  caseloadIndex: 0,
  currentTabCaseload: 0,
  resetPagination: false,
  isfilterObject: "",
  stored_startDate: null,
  stored_endDate: null,
  activeDateFilter:null
};

const initialChatUser = {
  selectedTab: 0,
  selectedDocTab: 0,
  selectedTaskTab: 1,
  selectedUser: null as number | null,
  conversationId: null as number | null,
  userListActive: {},
  isRefClosed: false as boolean,
};

export const LayoutReducer = (state = initialValues, action: any) => {
  switch (action.type) {
    case CHANGE_TAB:
      state = { ...state, currentTab: action?.tab };
      break;
    case CHANGE_TAB_CASELOAD:
      state = { ...state, caseloadIndex: action?.tab };
      break;
    case RESET_PAGINATION:
      state = { ...state, resetPagination: action?.tab };
      break;
    case OPEN_REF_URL:
      state = { ...state, openurlposL: action.payload };
      break;
    case LOADER_PDF:
      state = { ...state, loaderPdf: action.payload };
      break;
    case REF_CURRENT_TAB:
      state = { ...state, currentTabRef: action.payload };
      break;
    case CASELOAD_CURRENT_TAB:
      state = { ...state, currentTabCaseload: action.payload };
      break;
    case CASELOAD_FILTER_OBJECT:
      state = { ...state, isfilterObject: action.payload };
      break;
      case ACTIVE_DATE_FILTER:
        state = { ...state, activeDateFilter: action.payload };
        break;
    case CASELOAD_START_DATE:
      state = { ...state, stored_startDate: action.payload };
      break;
    case CASELOAD_END_DATE:
      return { ...state, stored_endDate: action.payload };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export const selectedTabReducer = (
  state = initialChatUser.selectedTab,
  action: any
) => {
  switch (action.type) {
    case SET_SELECTED_TAB:
      return action.payload;
    default:
      return state;
  }
};

export const selectedDocTabReducer = (
  state = initialChatUser.selectedDocTab,
  action: any
) => {
  switch (action.type) {
    case SET_DOCUMENT_TAB:
      return action.payload;
    default:
      return state;
  }
};

export const selectedTaskTabReducer = (
  state = initialChatUser.selectedTaskTab,
  action: any
) => {
  switch (action.type) {
    case SET_TASK_TAB:
      return action.payload;
    default:
      return state;
  }
};

export const selectedUserReducer = (
  state = initialChatUser.selectedUser,
  action: any
) => {
  switch (action.type) {
    case SET_SELECTED_USER:
      return action.payload;
    default:
      return state;
  }
};

export const conversationIdReducer = (
  state = initialChatUser.conversationId,
  action: any
) => {
  switch (action.type) {
    case SET_CONVERSATION_ID:
      return action.payload;
    default:
      return state;
  }
};

export const userListActiveReducer = (
  state = initialChatUser.userListActive,
  action: any
) => {
  switch (action.type) {
    case SET_USER_LIST_ACTIVE:
      return action.payload;
    case RESPONSE_CHATMSG_CLEAR:
      return [];
    default:
      return state;
  }
};

export const refClosedReducer = (
  state = initialChatUser.isRefClosed,
  action: any
) => {
  switch (action.type) {
    case REFFERAL_CLOSED:
      return action.payload;
    default:
      return state;
  }
};
