import { Suspense, memo, useEffect, useState } from "react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import "./resource.scss";
import {
  getResourceDoc,
  getResourceList,
} from "../../../redux/resources/action";
import { changeTab } from "../../../redux/Layout/action";
const CaseDocuments = React.lazy(
  () => import("../ManageCaseLoad/CaseDocuments")
);

function Index() {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const ResourceReducer = (state: any) => state?.ResourceDocumentReducer;
  const ResourcesData = createSelector(ResourceReducer, (state) => ({
    resourcesList: state,
  }));

  const { resourcesList } = useSelector(ResourcesData);

  useEffect(() => {
    dispatch(getResourceDoc({ page, search, limit }));
  }, [page, search, limit]);

  const handlePageCountChange = (newPageCount: number) => {
    setPage(newPageCount);
  };

  const handleLimitCountChange = (newLimitCount: number) => {
    setLimit(newLimitCount);
  };

  const handleSearchCountChange = (newSearchCount: string) => {
    setSearch(newSearchCount);
  };

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <CaseDocuments
        data={resourcesList?.docData?.data?.resources}
        addResourceBtn={true}
        onPageCountChange={handlePageCountChange}
        onLimitCountChange={handleLimitCountChange}
        onLimitSearchChange={handleSearchCountChange}
      ></CaseDocuments>
    </Suspense>
  );
}

export default memo(Index);
