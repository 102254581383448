import React from 'react'
import WhiteLayout from '../../components/common/WhiteLayout'


const WhitePageLayout = (props: any) => {
    return (
        <React.Fragment>
            <div id="layout-wrapper">
                <WhiteLayout>
                    <div className="main-content">{props.children}</div>
                </WhiteLayout>
            </div>
        </React.Fragment>
    )
}

export default WhitePageLayout