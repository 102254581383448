import { Box, Container } from "@mui/material";
import { BarChartCard } from "./BarChartCard";
import { StackedChartCard } from "./StackedChartCard";
import { ChartHeader } from "./ChartHeader";
import { useEffect, useMemo, useRef, useState } from "react";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import { getGraphList } from "../../../redux/action";
import constant from "../../../constants/constant";
import CustomDatePicker from "./CustomDatePicker";
import { initialKeyMappings, outcomeKeyMappings, outcomeOptions, reasonOptions, sourceOptions } from "./Options";
import { filterData, handleDownloadAllCharts, transformAvgMonths, transformInitially, transformMonths, transformOutcome, transformRejectedConditions, transformSource } from "./TransformFun";

const selectGraphData = createSelector(
  (state: any) => state?.userManagementReducer,
  (state) => state.graphData
);

interface OptionType {
  label: string;
  value: string;
}


const ReportCharts = () => {
  const dispatch = useDispatch();
  const [name,setName] = useState('');
  const [month,setMonth] = useState('12');
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<OptionType[]>([]);
  const [selectedfilteredOutcomeDataFilter, setSelectedfilteredOutcomeDataFilter] = useState<OptionType[]>([]);
  const [selectedfilteredInitialDataFilter, setSelectedfilteredInitialDataFilter] = useState<OptionType[]>([]);
  const [filteredOutcomeData, setFilteredOutcomeData] = useState<any>([]);
  const [filteredInitialData, setFilteredInitialData] = useState<any>([]);
  const graphData = useSelector(selectGraphData);
  const [graphLocalData, setGraphData] = useState(graphData);
  const { activation_rates, averageForCaseloadActivity,rejectConditions, source,initiallyAcceptedAndRejectedCount } = graphLocalData || {};
  const { parent, school } = activation_rates || {};
  const { avgParentDays,avgEducationDays,avgReadyToClinicianDays,avgReadyToClinicianToCloseDays,avgClosedDays,percentageOfReferralsMdtAccepted,percentageOfReferralsMdtRejected } = averageForCaseloadActivity || {};
  const transformedParentData = useMemo(() => transformMonths(parent), [parent]);
  const transformedSchoolData = useMemo(() => transformMonths(school), [school]);
  const transformedAvgParentData = useMemo(() => transformAvgMonths(avgParentDays), [avgParentDays]);
  const transformedAvgEducationData = useMemo(() => transformAvgMonths(avgEducationDays), [avgEducationDays]);
  const transformedAvgClinicianData = useMemo(() => transformAvgMonths(avgReadyToClinicianDays), [avgReadyToClinicianDays]);
  const transformedAvgClinicianClosedData = useMemo(() => transformAvgMonths(avgReadyToClinicianToCloseDays), [avgReadyToClinicianToCloseDays]);
  const transformedAvgClosedData = useMemo(() => transformAvgMonths(avgClosedDays), [avgClosedDays]);
  const transformedSourceData = useMemo(() => transformSource(source), [source]);
  const transformedOutcomeData = useMemo(() => transformOutcome(percentageOfReferralsMdtAccepted,percentageOfReferralsMdtRejected), [percentageOfReferralsMdtAccepted,percentageOfReferralsMdtRejected]);
  const transformedRejectConditions = useMemo(() => transformRejectedConditions(rejectConditions, constant.CLINICAL_REVIEW),[rejectConditions]);
  const transformedinitiallyAcceptedAndRejectedCountData = useMemo(() => transformInitially(initiallyAcceptedAndRejectedCount), [initiallyAcceptedAndRejectedCount]);

  useEffect(() => {
    if (graphData) { 
      setGraphData((prevData: any) => ({
        ...prevData,
        ...graphData,
        activation_rates: {
          ...prevData.activation_rates,
          ...graphData.activation_rates,
        },
        averageForCaseloadActivity: {
          ...prevData.averageForCaseloadActivity,
          ...graphData.averageForCaseloadActivity,
        },
      }));
    }
  }, [graphData]);
  
  useEffect(() => {
    const isSpecialFilter = [
      constant.FILTERS_NAME.source,
    ].includes(name);
  
    // if (name === constant.FILTERS_NAME.percentageOfReferralsMdtAcceptedOrRejected || name === constant.FILTERS_NAME.initiallyAcceptedAndRejectedCount) {
    //   return;
    // }
  
    const payload: any = {
      month,
      result1Filter: isSpecialFilter ? [] : selectedFilter,
      ...(name && { name }),
      ...(isSpecialFilter && { status: selectedFilter }), 
    };
  
    dispatch(getGraphList(payload));
  }, [dispatch, month, name, selectedFilter]);


  const rejectionLabels = Object.values(constant.CLINICAL_REVIEW);  


  useEffect(() => {
    const outcomeSelectedKeys:any = selectedfilteredOutcomeDataFilter.length > 0
      ? selectedfilteredOutcomeDataFilter.filter((filter:any) => filter in outcomeKeyMappings)
      : [];
    const filteredOutcomeData:any = filterData(transformedOutcomeData, outcomeSelectedKeys, outcomeKeyMappings);
    setFilteredOutcomeData(filteredOutcomeData);
    const initialSelectedKeys:any = selectedfilteredInitialDataFilter.length > 0
      ? selectedfilteredInitialDataFilter.filter((filter:any) => filter in initialKeyMappings)
      : [];
    const filteredInitialData = filterData(transformedinitiallyAcceptedAndRejectedCountData, initialSelectedKeys, initialKeyMappings);
    setFilteredInitialData(filteredInitialData);
  }, [selectedfilteredInitialDataFilter, selectedfilteredOutcomeDataFilter, transformedOutcomeData, transformedinitiallyAcceptedAndRejectedCountData]);

  const chartConfigs = [
    { title: "Account Activation Rate By Parents",openDatePicker,setOpenDatePicker, data: transformedParentData, color: "#7B9DFF",setMonth,month,name:constant.FILTERS_NAME.activationRatesParent,setName },
    { title: "Account Activation Rate By Schools",openDatePicker,setOpenDatePicker, data: transformedSchoolData, color: "#8C44F7",setMonth,month,name:constant.FILTERS_NAME.activationRatesSchool,setName },
    { title: "Average time of parent report submission",openDatePicker,setOpenDatePicker, data: transformedAvgParentData, color: "#6A2382",setMonth,month,name:constant.FILTERS_NAME.avgParentDays,setName },
    { title: "Average time of Education report submission",openDatePicker,setOpenDatePicker, data: transformedAvgEducationData, color: "#99D7CA",setMonth,month,name:constant.FILTERS_NAME.avgEducationDays,setName },
    { title: "Average time to gather all required referral details",openDatePicker,setOpenDatePicker, data: transformedAvgClinicianData, color: "#4A3AFF",setMonth,month,name:constant.FILTERS_NAME.avgReadyToClinicianDays,setName },
    { title: "Average time taken to review completed referrals",openDatePicker,setOpenDatePicker, data: transformedAvgClinicianClosedData, color: "#962DFF",setMonth,month,name:constant.FILTERS_NAME.avgReadyToClinicianToCloseDays,setName },
    { title: "Average time from referral receipt to Initial recording",openDatePicker,setOpenDatePicker, data: transformedAvgClosedData, color: "#C57D51",setMonth,month,name:constant.FILTERS_NAME.avgClosedDays,setName },
  ];
  const stackChartConfigs = [
    { title: "Number of referrals from different source",openDatePicker,setOpenDatePicker, data: transformedSourceData,keys:["admin", "openUrl", "school"], colors: ["#962DFF", "#E0C6FD", "#93AAFD"],options:sourceOptions,setMonth,month,name:constant.FILTERS_NAME.source,setName,selectedFilter, setSelectedFilter,   },
    { title: "Number of outcome accepted or rejected for further assessment",openDatePicker,setOpenDatePicker, data: filteredOutcomeData,keys:["accept", "reject"], colors: ["#6A2382","#E0C6FD"],options:outcomeOptions,setMonth,month,name:constant.FILTERS_NAME.percentageOfReferralsMdtAcceptedOrRejected,setName,selectedfilteredOutcomeDataFilter, setSelectedfilteredOutcomeDataFilter  },
    { title: "Referrals initially accepted/rejected (in Days / Percentage)",openDatePicker,setOpenDatePicker, data: filteredInitialData,keys:["accepted_percentage", "rejected_percentage"], colors: ["#E0C6FD", "#962DFF", "#93AAFD"],options:outcomeOptions,setMonth,month,name:constant.FILTERS_NAME.initiallyAcceptedAndRejectedCount,setName,selectedfilteredInitialDataFilter, setSelectedfilteredInitialDataFilter  },
    { title: "Number of rejections recorded against each listed reason for rejection",openDatePicker,setOpenDatePicker, data: transformedRejectConditions,keys: rejectionLabels,selectedFilter, setSelectedFilter, 
      colors: ["#6A2382", "#E0C6FD", "#962DFF", "#93AAFD", "#4A3AFF", "#99D7CA", "#8C44F7", "#7B9DFF", "#C57D51"],options:reasonOptions,setMonth,month,name:constant.FILTERS_NAME.rejectConditions,setName 
    },
  ];

  const handleOptionSelection = (value:any,name:any) => {
    if(name === constant.FILTERS_NAME.percentageOfReferralsMdtAcceptedOrRejected){
      setSelectedfilteredOutcomeDataFilter(value)
    }else if(name === constant.FILTERS_NAME.initiallyAcceptedAndRejectedCount){
      setSelectedfilteredInitialDataFilter(value)
    }else(
      setSelectedFilter(value)
    )
  }

  const chartRefs = useRef<any>([]);

  useEffect(() => {
    chartRefs.current = chartRefs.current.slice(0, chartConfigs.length); 
  }, [chartConfigs.length]);

  const handleClick = () => {
    handleDownloadAllCharts(chartRefs?.current.filter((ref:any) => ref !== null))
  };

  return (
    <Box fontFamily={"poppins"}>
       <CustomDatePicker openDatePicker={openDatePicker} setOpenDatePicker={setOpenDatePicker} setMonth={setMonth} />
      <ChartHeader  handleClick={handleClick} setMonth={setMonth} month={month} name={name} setName={setName} openDatePicker={openDatePicker} setOpenDatePicker={setOpenDatePicker}/>
      <Container maxWidth="xl">
        {chartConfigs.map(({ title, data, color, setMonth, month,name,setName,openDatePicker,setOpenDatePicker }, index) => (
          <BarChartCard  ref={(el:any) => (chartRefs.current[index] = el)} key={index} title={title} data={data} color={color} setMonth={setMonth} month={month} name={name} setName={setName} openDatePicker={openDatePicker} setOpenDatePicker={setOpenDatePicker}/>
        ))}
          {stackChartConfigs.map(({ title, data, keys,colors,options,setMonth, month,name,setName,selectedFilter, setSelectedFilter,selectedfilteredInitialDataFilter, setSelectedfilteredInitialDataFilter,selectedfilteredOutcomeDataFilter, setSelectedfilteredOutcomeDataFilter, openDatePicker,setOpenDatePicker }, index) => (
          <StackedChartCard handleOptionSelection={handleOptionSelection} options={options}  key={index} title={title} data={data} keys={keys} colors={colors} setMonth={setMonth} month={month} name={name} setName={setName} selectedFilter={selectedFilter} setSelectedFilter={setSelectedFilter} selectedfilteredInitialDataFilter={selectedfilteredInitialDataFilter} setSelectedfilteredInitialDataFilter={setSelectedfilteredInitialDataFilter} selectedfilteredOutcomeDataFilter={selectedfilteredOutcomeDataFilter} setSelectedfilteredOutcomeDataFilter={setSelectedfilteredOutcomeDataFilter} openDatePicker={openDatePicker} setOpenDatePicker={setOpenDatePicker}/>
        ))}
      </Container>
    </Box>
  );
};

export default ReportCharts;
















// import { Box, Container } from "@mui/material";
// import { BarChartCard } from "./BarChartCard";
// import { StackedChartCard } from "./StackedChartCard";
// import { ChartHeader } from "./ChartHeader";
// import CustomDatePicker from "./CustomDatePicker";
// import { useReportsCharts } from "./Hooks/UseReportsCharts";

// const ReportCharts = () => {
//   const { 
//     ChartRenderer,
//     handleOptionSelection,
//     stackChartConfigs,
//     handleClick,
//     openDatePicker,
//     setOpenDatePicker,
//     setMonth,
//     setName,
//     chartConfigs,
//     month,
//     name
//   } = useReportsCharts()

//   return (
//     <Box fontFamily={"poppins"}>
//        <CustomDatePicker openDatePicker={openDatePicker} setOpenDatePicker={setOpenDatePicker} setMonth={setMonth} />
//       <ChartHeader  handleClick={handleClick} setMonth={setMonth} month={month} name={name} setName={setName} openDatePicker={openDatePicker} setOpenDatePicker={setOpenDatePicker}/>
//       <Container maxWidth="xl">
//         <ChartRenderer config={chartConfigs} Component={BarChartCard} />
//         <ChartRenderer config={stackChartConfigs} Component={StackedChartCard} extraProps={{ handleOptionSelection }} />
//       </Container>
//     </Box>
//   );
// };

// export default ReportCharts;
