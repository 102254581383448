import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";
import { Box, Typography, Card, CardContent } from "@mui/material";
import ActionGroup from "./ActionGroup";
import React, { useRef, useState } from "react";
import html2canvas from "html2canvas";
import { styles } from "./Styles";
import { sortDataByYearAndMonth } from "./TransformFun";

export const BarChartCard = React.forwardRef(({ title, data, color, setMonth, month, setName, name, setOpenDatePicker }: any, ref) => {
  const chartRef = useRef<any>();
  const [activeTab, setActiveTab] = useState<{ name: string; value: any }>({
    name: name,
    value: "12",
  });
  const combinedRef = (node: HTMLDivElement | null) => {
    chartRef.current = node;
    if (ref && typeof ref !== 'function') {
      (ref as React.MutableRefObject<HTMLDivElement | null>).current = node;
    }
  };

  const handleChange = (
    _event: React.MouseEvent<HTMLElement>,
    newValue: string | null
  ) => {

    if (newValue === "custom"){
      setOpenDatePicker(true);
      setName(name)
    }else{
      setMonth(newValue);
      setName(name)
    }
    setActiveTab({value:newValue,name:name})
  };

  const handleDownload = async () => {
    if (chartRef.current) {
      const canvas = await html2canvas(chartRef.current, { backgroundColor: "#fff" });
      const image = canvas.toDataURL("image/png"); 
      const link = document.createElement("a");
      link.href = image;
      link.download = `${title}.png`; 
      link.click();
    }
  };

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: "rgba(30, 27, 57, 1)",
            color: "#fff",
            padding: "8px 12px",
            borderRadius: "5px",
            position: "relative",
            textAlign: "center",
          }}
        >
          <div
            style={{
              position: "absolute",
              bottom: "-6px",
              left: "50%",
              transform: "translateX(-50%)",
              width: "0",
              height: "0",
              borderLeft: "6px solid transparent",
              borderRight: "6px solid transparent",
              borderTop: "6px solid black",
            }}
          ></div>
          <p style={{ margin: 0 }}>{name.includes("avg") ? `${payload[0].value} Days` : `${payload[0].value}%`}</p>
          <p style={{ margin: 0, fontSize: "12px", opacity: 0.8 }}> {name.includes("avg") ? 'Average Time' : 'Account Active'}</p>
        </div>
      );
    }
  
    return null;
  };
  
  console.log(data,'data')

  const sortedData = sortDataByYearAndMonth(data);
  console.log(sortedData)

  return (
    <Card
      sx={styles.graphCard}
      ref={combinedRef}
    >
      <CardContent sx={{p:0}}>
        <Box sx={{ display: "flex", justifyContent: "space-between",mb:5 }}>
          <Box>
            <Typography
              component={"small"}
              gutterBottom
              color={"#9291A5"}
              fontFamily={"poppins"}
            >
              Statistics
            </Typography>

            <Typography
              fontWeight="bold"
              gutterBottom
              fontSize={"22px"}
              color={"#1E1B39"}
            >
              {title}
            </Typography>
          </Box>
          <ActionGroup
             name={name}
            value={month}
            onChange={handleChange}
            onDownload={handleDownload}
            isActiveTab={activeTab}
          />
        </Box>
        <ResponsiveContainer width="100%" height={450} >
          <BarChart data={sortedData}>
            <CartesianGrid strokeDasharray="5" vertical={false} stroke="rgba(229, 229, 239, 1)"/>
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip content={<CustomTooltip />} />
            <Bar
              dataKey={"value"}
              fill={color}
              barSize={50}
              radius={[5, 5, 0, 0]}
            />
          </BarChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
});
