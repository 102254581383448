export const styles = {
  chartHeaderTitle: {
    bgcolor: "#6A2382",
    minHeight: "85px",
    color: "#fff",
    fontFamily: "poppins",
    display: "flex",
    alignItems: "center",
    px: 5,
    mb: 5,
    justifyContent: "space-between",
    boxShadow:"2px 2px 10px 2px #0000004f",

  },
  toggleBtn: {
    background: "#F8F8FF",
    py:0.8,
    px:1,
    gap: "1rem",
    "& .MuiToggleButton-root": {
      border: "none",
      "&.Mui-selected": {
        background: "#6A2382",
        color: "#fff",
        borderRadius: "4px",
      },
    },
  },
  downloadBtn: {
    py: 1.5,
    px: 2,
    ml: 2,
    background: "#F8F8FF",
    boxShadow: "none",
    color: "#6A2382",
    "&:hover": {
      borderColor: "#6A2382",
      color: "#6A2382",
      boxShadow: "none",
      'svg':{
        fill:'#fff'
      }
    },
  },
  graphCard:{
    p: 2,
    mb: 3,
    boxShadow: "none",
    border: "1px solid #E7E7E7",
    borderRadius: "10px",
  }
};
