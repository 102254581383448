import React, { useState } from "react";
import { Box, ToggleButtonGroup, ToggleButton, Button } from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CloseIcon from "@mui/icons-material/Close";
import { styles } from "./Styles";

const actionGroup = ({
  value,
  name,
  onChange,
  toggleOptions = [
    { label: "Custom", value: "custom" },
    { label: "3M", value: "3" },
    { label: "6M", value: "6" },
    { label: "Default", value: "12" },
  ],
  onDownload,
  onClose,
  isActiveTab
}:any) => {
  const isActive = isActiveTab?.name === name;
  const activeValue = isActive ? isActiveTab?.value : value; 
  return (
    <Box display="flex" alignItems="center" gap={1}>
      <ToggleButtonGroup
        size="small"
        exclusive
        value={activeValue}
        onChange={onChange}
        sx={{...styles.toggleBtn,
          backgroundColor: isActive ? "#F8F8FF" : "#fff",
          
        }}
      >
        {toggleOptions.map((option:any) => (
          <ToggleButton key={option.value} value={option.value}>
            {option.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>

      <Button variant="contained" sx={styles.downloadBtn} onClick={onDownload}>
        <CloudDownloadIcon />
      </Button>
     {onClose && <Button variant="contained" sx={styles.downloadBtn} onClick={onClose}>
        <CloseIcon />
      </Button>}
    </Box>
  );
};

export default actionGroup;
