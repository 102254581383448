export const VIEW_SCHOOL_REPORT = "VIEW_SCHOOL_REPORT"
export const ADD_SCHOOL_REPORT = "ADD_SCHOOL_REPORT"
export const REPORT_SCHOOL_RESPONSE = "REPORT_SCHOOL_RESPONSE"
export const GET_REPORT_CASELOAD_DETAIL = "GET_REPORT_CASELOAD_DETAIL"
export const REPORT_CASELOAD_RESPONSE = "REPORT_CASELOAD_RESPONSE"
export const GET_REPORT_SCHOOL_ALL_DETAIL = "GET_REPORT_SCHOOL_ALL_DETAIL"
export const GET_REPORT_SCHOOL_ALL_RESPONSE = "GET_REPORT_SCHOOL_ALL_RESPONSE"
export const EDIT_SCHOOL_REPORT = "EDIT_SCHOOL_REPORT"
export const GET_PATIENT_NAME = "GET_PATIENT_NAME"
export const GET_REPORT_BY_ID = "GET_REPORT_BY_ID"
export const GET_REPORT_DETAIL = "GET_REPORT_DETAIL"

