import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { dashboard } = Dictionary

const Dashboard = {
  view: async (paramsObj: any) => {
    return axios.get(BASE_URL + dashboard.dashboard(), { params: paramsObj })
  },
  getReportCharts: async (data: {}) => {
    let url = BASE_URL + dashboard.ReportCharts();

    // Check if "name" key is present in data
    if ("name" in data) {
        url += "?filter=true";
    }

    return axios.post(url, data);
},
}
export default Dashboard
