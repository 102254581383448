import html2canvas from "html2canvas";
import JSZip from "jszip";

export const getMonthName = (month: string, uppercase = false) => {
  const monthMap: Record<string, string> = {
    "01": "Jan",
    "02": "Feb",
    "03": "Mar",
    "04": "Apr",
    "05": "May",
    "06": "Jun",
    "07": "Jul",
    "08": "Aug",
    "09": "Sep",
    "10": "Oct",
    "11": "Nov",
    "12": "Dec",
  };
  return uppercase ? monthMap[month]?.toUpperCase() : monthMap[month];
};

export const transformMonths = (data: any) => {
  const monthOrder = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return (
    data
      ?.map((item: any) => ({
        ...item,
        name: getMonthName(item.month.split("-")[1]),
        value: parseInt(item.activationRate),
      }))
      .sort(
        (a: any, b: any) =>
          monthOrder.indexOf(a.name) - monthOrder.indexOf(b.name)
      ) || []
  );
};

export const transformAvgMonths = (data: Record<string, { count: number; avg_days: number }> = {}) => {
  const monthOrder = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];

  return Object.entries(data)
    .map(([key, value]) => {
      const [year, month] = key.split("-");
      return {
        name: getMonthName(month, true),
        value: parseFloat(value?.avg_days as any),
        year: parseInt(year),
      };
    })
    .sort((a, b) => a.year - b.year || monthOrder.indexOf(a.name) - monthOrder.indexOf(b.name))
    .map(({ name, value }) => ({ name, value }));
};


export const transformSource = (data: any) =>
  (
    data?.map((item: any) => {
      const [year, month] = item.month.split("-");
      return {
        name: getMonthName(month), // Sirf month display hoga
        monthIndex: parseInt(month), // Sorting ke liye month index
        year: parseInt(year), // Sorting ke liye year rakh rahe hain
        admin: item.submitted_by[3],
        openUrl: item.submitted_by[6],
        school: item.submitted_by[9],
      };
    }) || []
  )
    .sort((a:any, b:any) => a.year - b.year || a.monthIndex - b.monthIndex)
    .map(({ year, ...rest }:any) => rest); 

    export const transformOutcome = (
      acceptedData: Record<string, any> = {},
      rejectedData: Record<string, any> = {}
    ) =>
      Object.entries(acceptedData)
        .map(([key, value]) => {
          const [year, month] = key.split("-");
          return {
            name: getMonthName(month, true), // Sirf month display hoga
            monthIndex: parseInt(month), // Sorting ke liye month index
            year: parseInt(year), // Sorting ke liye year
            accept: parseFloat(value.percentage),
            reject: parseFloat(rejectedData[key]?.percentage || "0"),
          };
        })
        .sort((a, b) => a.year - b.year || a.monthIndex - b.monthIndex)
        .map(({ year, ...rest }) => rest); // Final output se year remove kar diya
    
    

  export const transformInitially = (data: any) =>
  (
    data?.map((item: any) => {
      const [year, month] = item.month.split("-");
      return {
        name: getMonthName(month), // Sirf month display hoga
        monthIndex: parseInt(month), // Sorting ke liye month index
        year: parseInt(year), // Sorting ke liye year
        accepted_count: item.accepted_count,
        accepted_percentage:
          (item.accepted_count / (item.total_referrals || 1)) * 100,
        rejected_percentage:
          (item.rejected_count / (item.total_referrals || 1)) * 100,
        rejected_count: item.rejected_count,
        total_referrals: item.total_referrals,
      };
    }) || []
  )
    .sort((a:any, b:any) => a.year - b.year || a.monthIndex - b.monthIndex)
    .map(({ year, ...rest }:any) => rest); // Final output se year remove kar diya



  export const transformRejectedConditions = (
    data: any,
    clinicalReview: Record<string, string>
  ) =>
    (
      data?.map((item: any) => {
        const [year, month] = item.month.split("-");
        const monthNumber = parseInt(month);
  
        const transformedItem: Record<string, any> = {
          name: getMonthName(month), 
          monthIndex: monthNumber, 
          year: parseInt(year),
        };
  
        item.breakdown.forEach((entry: any) => {
          const label = clinicalReview[entry.result1];
          if (label) {
            transformedItem[label] = parseInt(entry.percentage);
          }
        });
  
        return transformedItem;
      }) || []
    )
      .sort((a:any, b:any) => a.year - b.year || a.monthIndex - b.monthIndex)
      .map(({ year, ...rest }:any) => rest); 
  
  

  export const filterData = (data: any[], selectedKeys: string[], keyMappings: Record<string, string>) => {
    return selectedKeys.length === 0
      ? data
      : data.map((item: any) => {
          const newItem: any = { name: item.name, monthIndex: item.monthIndex };
          selectedKeys.forEach((key) => {
            if (key in keyMappings) {
              newItem[keyMappings[key]] = item[keyMappings[key]] ?? 0;
            }
          });
          return newItem;
        });
  };

  
  export const handleDownloadAllCharts = async (refs: React.RefObject<HTMLDivElement>[]) => {
    const images = await Promise.all(
      refs.map(async (ref) => {
        if (ref.current) {
          const canvas = await html2canvas(ref.current, { backgroundColor: '#fff' });
          return canvas.toDataURL('image/png');
        }
        return null;
      })
    );
    const zip = new JSZip();
    images.forEach((image, index) => {
      if (image) {
        zip.file(`chart_${index + 1}.png`, image.split(',')[1], { base64: true });
      }
    });
    const content = await zip.generateAsync({ type: 'blob' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(content);
    link.download = 'charts.zip';
    link.click();
  };

  export const sortDataByYearAndMonth = (data: any[]) => {
    return [...data].sort((a, b) => {
      const dateA = new Date(a.month); // Convert to Date object
      const dateB = new Date(b.month);
      return dateA.getTime() - dateB.getTime(); // Sort in ascending order
    });
  };